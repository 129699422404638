// 現在のURLのクエリパラメーターを取得する
function getQueryParams(key = null) {
  const params = new URLSearchParams(window.location.search);
  let queryParams = {};
  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }
  if (key) {
    const val = queryParams[key];
    return (!!val) ? {[key]: val} : {};
  } else {
    return queryParams;
  }
}

// パラメータを引き継いで新しいURLに遷移する関数
function redirectTo(url, params = {}) {
  if (Object.keys(params).length === 0) {
    params = getQueryParams();
  }
  params = new URLSearchParams(params);
  window.location.href = `${url}?${params.toString()}`;
}
export { getQueryParams, redirectTo };
