$(function() {
  $('#complete-signup').on('click', () => {
    $("#js-confirm-coupon-modal").addClass('is-open');
  });

  $('#event-ticket').on('click', () => {
    $("#js-confirm-coupon-modal-event-ticket").addClass('is-open');
  });

  $('.js-coupon-modal-close').on('click', () => {
    $("#js-confirm-coupon-modal").removeClass('is-open');
  });
});
