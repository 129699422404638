import { openStoreDetail } from './store_common';

window.odometerOptions = {
  format: 'd',
  duration: 700,
  animation: 'count'
};

$(document).ready(function() {
  let defaultPlace;
  let apiKey;
  let limit = 20;
  let offset = 0;
  let isSunday = false;
  let isMonday = false;
  let isTuesday = false;
  let isWednesday = false;
  let isThursday = false;
  let isFriday = false;
  let isSaturday = false;
  let isHoliday = false;
  let isPreviousHoliday = false;
  let fixedPlaceName = null;
  let fixedGenre = null;
  let fixedPlaceId = null;
  let fixedLocation = null;
  let selectedTabStoreFlg = true;
  let currentSelectedPlaceName = null;
  let currentSelectedGenre = null;
  let currentSelectedPlaceId = null;
  let currentLocation = null;
  const calendarApiUrl = 'https://www.googleapis.com/calendar/v3/calendars/japanese__ja@holiday.calendar.google.com/events?';

  // 検索画面の場合
  if ($('#search').length != 0) {
    // データを取得
    getData();

    // 今日が祝日or祝前日か取得
    setIsTodayTomorrowHoliday();

    // イベント系の設定
    setEvent();

    // URLに店舗名が存在する場合、テキストフィールドにセットして検索
    const name = getParam('name');
    $('#js-name-input-field').val(name);

    // 加盟店検索
    searchStore(true);
    searchStoreCount();
  }

  // データを取得
  function getData() {
    // GCPのAPIキーを取得
    apiKey = $('#search').data('api-key');
    // デフォルトの位置情報を取得
    defaultPlace = $('#search').data('default-place');
    // URLパラメーターに位置情報がある場合、デフォルトの位置情報を上書き
    if (getParam('latitude') && getParam('longitude')) {
      defaultPlace.position = {
        lat: getParam('latitude'),
        lng: getParam('longitude')
      };
      defaultPlace.name = '現在地';
    }
  }

  // イベント系の設定
  function setEvent() {
    // エリアボタンのイベント設定
    setPlaceButtonEvent();

    // ジャンルボタンのイベント設定
    setGenreButtonEvent();

    // スクロールイベントの設定
    setScrollEvent();

    // 絞り込みタップイベント
    $('#js-detail-search-button').on('click', function() {
      // スクロールイベントを解除
      $(window).off('scroll');
      // 詳細画面を表示
      $('#js-basic').hide();
      $('#js-detail').show();
    });

    // 詳細閉じるボタンタップイベント
    $('#js-close-detail').on('click', function() {
      // スクロールイベントを設定
      setScrollEvent();
      // ジャンルをリセット
      $('.js-genre-button').removeClass('is-selected');
      if (fixedGenre) $('[data-genre="' + fixedGenre + '"]').addClass('is-selected');
      // 現在地をリセット
      $('#js-current-location').removeClass('is-selected');
      if (fixedLocation) $('#js-current-location').addClass('is-selected');
      // エリアをリセット
      $("input[name=station]").val(fixedPlaceName);
      $('.js-place-button').removeClass('is-selected');
      if (fixedPlaceId) $('[data-place-id="' + fixedPlaceId + '"]').addClass('is-selected');
      currentSelectedPlaceName = fixedPlaceName;
      currentSelectedGenre = fixedGenre;
      currentSelectedPlaceId = fixedPlaceId;
      currentLocation = fixedLocation;
      searchStoreCount();
      // 基本画面を表示
      $('#js-basic').show();
      $('#js-detail').hide();
    });

    // 詳細検索ボタンタップイベント
    $('#js-search-button').on('click', function() {
      fixedPlaceName = currentSelectedPlaceName;
      fixedGenre = currentSelectedGenre;
      fixedPlaceId = currentSelectedPlaceId;
      fixedLocation = currentLocation;
      // セッション管理（エリア）
      setSessionPlaces();
      // 加盟店検索
      resetStoreList();
      searchStore();
      // スクロールイベントを設定
      setScrollEvent();
      // 基本画面を表示
      $('#js-basic').show();
      $('#js-detail').hide();
    });

    // テキストでエンターキーを押されたときの処理
    $('#js-name-input-field').keypress(function(e) {
      if(e.keyCode === 13) {
        resetStoreList();
        searchStore();
        searchStoreCount();
      }
    });

    // 営業日トグルイベント
    $('#js-store-display-toggle').on('change', function() {
      resetStoreList();
      searchStore();
      searchStoreCount();
    });

    // 現在地ボタンイベント
    $('#js-current-location').on('click', function() {
      if ($(this).hasClass('is-selected')) {
        // 現在地ボタンをリセット
        resetCurrentLocation();
        searchStoreCount();
      } else {
        // 現在地の取得
        getCurrentLocation();
      }
    });

    // 駅名検索イベント
    $("input[name=station]").focusout(function() {
      const selected = $([].slice.call($(this)[0].list.children).filter((e) => {
        return e.value === $(this).val();
      }));

      const placeId = selected.attr('data-place-id');
      const placeName = selected.val();
      if (!placeId) {
        resetPlace();
      } else {
        currentSelectedPlaceId = placeId;
        currentSelectedPlaceName = placeName;
        resetCurrentLocation();
        $('.js-place-button').removeClass('is-selected');
        $('[data-place-id="' + placeId + '"]').addClass('is-selected');
      }
      searchStoreCount();
    });

    // 店名/エリア・ジャンル検索イベント
    $('.nav-link').on('click', function() {
      const selectedTabId = $(this).attr('id');
      if (selectedTabId === 'pills-store-tab') {
        selectedTabStoreFlg = true;
      } else {
        selectedTabStoreFlg = false;
      }
    });
  }

  // 現在地のリセット
  function resetCurrentLocation() {
    $('#js-current-location').removeClass('is-selected');
    currentLocation = null;
  }

  // 現在地の取得
  function getCurrentLocation() {
    $("#spinner-div").show();
    // 位置情報が利用できない場合の処理
    const disableCurrentLocation = function() {
      $('#js-current-location').addClass('is-disabled');
      $('#js-current-location').off('click');
      alert('このブラウザでは位置情報が利用できません');
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        currentLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        // エリアのリセット
        resetPlace();
        $('#js-current-location').addClass('is-selected');
        searchStoreCount();
      }, function (error) {
        disableCurrentLocation();
      });
    } else {
      disableCurrentLocation();
    }
    $("#spinner-div").hide();
  }

  // 店舗リストとoffsetを初期化
  function resetStoreList() {
    offset = 0;
    const storeListId = selectedTabStoreFlg ? '#js-store-list-store' : '#js-store-list-area-and-genre';
    $(storeListId).children().remove();
  }

  // スクロールイベントの設定
  function setScrollEvent() {
    $(window).on('scroll', function() {
      const scrollHeight = $(document).height();
      const scrollPosition = $(window).height() + $(window).scrollTop();

      // スクロールが最下部に達したら追加の店舗情報を取得
      if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
        searchStore();
      }
    });
  }

  // ジャンルボタンのイベント設定
  function setGenreButtonEvent() {
    $('.js-genre-button').off('click');
    $('.js-genre-button').on('click', function() {
      const genre = $(this).data('genre');
      if (currentSelectedGenre === genre) {
        currentSelectedGenre = null;
        $('[data-genre="' + genre + '"]').removeClass('is-selected');
      } else {
        currentSelectedGenre = genre;
        $('.js-genre-button').removeClass('is-selected');
        $('[data-genre="' + genre + '"]').addClass('is-selected');
      }
      searchStoreCount();
    });
  }

  // エリアボタンのイベント設定
  function setPlaceButtonEvent() {
    $('.js-place-button').off('click');
    $('.js-place-button').on('click', function() {
      const placeId = $(this).data('place-id');
      if (currentSelectedPlaceId === placeId) {
        resetPlace();
      } else {
        currentSelectedPlaceId = placeId;
        currentSelectedPlaceName = $(this).text();
        $('#js-station-datalist').val(currentSelectedPlaceName);
        $('.js-place-button').removeClass('is-selected');
        $('[data-place-id="' + placeId + '"]').addClass('is-selected');
        // 現在地のリセット
        resetCurrentLocation();
      }
      searchStoreCount();
    });
  }

  // エリアのリセット
  function resetPlace() {
    currentSelectedPlaceId = null;
    currentSelectedPlaceName = null;
    $('#js-station-datalist').val('');
    $('[data-place-id]').removeClass('is-selected');
  }

  // 加盟店をクリックしたときのイベント設定
  function setStoreClickEvent() {
    $('.js-open-store-detail').on('click', function() {
      const storeId = $(this).data('store-id');
      openStoreDetail(storeId);
    });
  }

  // エリアとジャンル名の設定
  function setPlaceAndGenreText() {
    const place = fixedPlaceName || 'ー';
    const genre = fixedGenre || 'ー';
    $('#js-place-and-genre').text(place + '/' + genre);
    if (fixedPlaceName) {
      $('#js-sort').text(place + 'から近い順');
    } else if (fixedLocation) {
      $('#js-sort').text('現在地から近い順');
    } else {
      $('#js-sort').text(`${defaultPlace.name}から近い順`);
    }
  }

  // 店舗情報を取得
  function searchStore(firstLoad = false) {
    $("#spinner-div").show();
    $.ajax({
      url: 'search',
      type: 'POST',
      dataType: 'json',
      data: getSearchParams({ offset: offset, limit: limit }),
      timeout: 10000,
    }).done(function (data) {
      // エリアとジャンルのテキストを設定
      setPlaceAndGenreText();
      // 履歴（エリア）の設定
      setPlaceHistoryHtml();
      // オフセットの設定
      offset += limit;
      data.forEach(store => {
        const storeListId = selectedTabStoreFlg ? '#js-store-list-store' : '#js-store-list-area-and-genre';
        $(storeListId).append(storeHtml(store));
        if (firstLoad) $('#js-store-list-area-and-genre').append(storeHtml(store));
      });
      // 加盟店リストのクリックイベント
      setStoreClickEvent();
    }).fail(function(e) {
      console.log(e);
    }).always(function() {
      $("#spinner-div").hide();
    });
  }

  // 店舗件数を取得
  function searchStoreCount() {
    $("#spinner-div").show();
    $.ajax({
      url: 'search/count',
      type: 'POST',
      dataType: 'json',
      data: getSearchParams(),
      timeout: 10000,
    }).done(function (data) {
      // 店舗件数のセット
      $('.odometer').html(data.total_count);
    }).fail(function(e) {
      console.log(e);
    }).always(function() {
      $("#spinner-div").hide();
    });
  }

  // 店舗検索APIに渡すパラメータを取得
  function getSearchParams(optionParams = {}) {
    const latitude = currentLocation?.latitude || defaultPlace.position.lat;
    const longitude = currentLocation?.longitude || defaultPlace.position.lng;
    const name = $('#js-name-input-field').val();
    const storeDisplayToggle = $('#js-store-display-toggle').prop('checked');

    const baseParams = {
      latitude: latitude,
      longitude: longitude,
      sunday: storeDisplayToggle ? isSunday : true,
      monday: storeDisplayToggle ? isMonday : true,
      tuesday: storeDisplayToggle ? isTuesday : true,
      wednesday: storeDisplayToggle ? isWednesday : true,
      thursday: storeDisplayToggle ? isThursday : true,
      friday: storeDisplayToggle ? isFriday : true,
      saturday: storeDisplayToggle ? isSaturday : true,
      holiday: storeDisplayToggle ? isHoliday : true,
      previous_holiday: storeDisplayToggle ? isPreviousHoliday : true,
    }
    const storeNameParams = { name: name };
    const areaAndGenreParams = { genres: currentSelectedGenre ? [currentSelectedGenre] : [], place_id: currentSelectedPlaceId };
    const fixedParams = selectedTabStoreFlg ? Object.assign(baseParams, storeNameParams) : Object.assign(baseParams, areaAndGenreParams);
    return Object.assign(fixedParams, optionParams);
  }

  // URLからパラメータを取得
  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  // 今日が祝日or祝前日or何曜日か取得
  function setIsTodayTomorrowHoliday() {
    const today = new Date();
    const todayString = today.toISOString().substring(0, 10);

    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowString = tomorrow.toISOString().substring(0, 10);

    $.ajax({
      dataType: "json",
      url: calendarApiUrl,
      data: {
        key: apiKey,
        timeMin: `${todayString}T00:00:00Z`,
        timeMax: `${tomorrowString}T12:00:00Z`
      },
      async: false
    }).done(function(data) {
      $.each(data.items, function(index, item) {
        if (item.start.date == todayString) {
          isHoliday = true;
        }
        if (item.start.date == tomorrowString) {
          isPreviousHoliday = true;
        }
      });
      const dayOfWeek = new Date().getDay();
      switch (dayOfWeek) {
          case 0:
            isSunday = true;
            break;
          case 1:
            isMonday = true;
            break;
          case 2:
            isTuesday = true;
            break;
          case 3:
            isWednesday = true;
            break;
          case 4:
            isThursday = true;
            break;
          case 5:
            isFriday = true;
            break;
          case 6:
            isSaturday = true;
            break;
        }
    }).fail(function(error) {
      console.log(error);
    });
  }

  // 距離の単位変換
  function ConversionDistance(distance) {
    if (distance < 1) {
      return Math.round(distance * 1000) + 'm';
    } else {
      return Math.round(distance) + 'km';
    }
  }

  // セッション（エリア）を取得
  function getSessionPlaces() {
    const sessionPlacesString = sessionStorage.places || '';
    let sessionPlaces = sessionPlacesString.split('@@').filter(Boolean);
    sessionPlaces = sessionPlaces.map(place => JSON.parse(place));
    return sessionPlaces;
  }

  // セッション（エリア）を設定
  function setSessionPlaces() {
    const sessionPlacesString = sessionStorage.places || '';
    let sessionPlaces = sessionPlacesString.split('@@').filter(Boolean);
    sessionPlaces = sessionPlaces.map(place => JSON.parse(place));
    sessionPlaces = sessionPlaces.filter(place => place['id'] != fixedPlaceId);
    if (fixedPlaceId && fixedPlaceName) sessionPlaces.unshift({ id: fixedPlaceId, name: fixedPlaceName });
    sessionPlaces = sessionPlaces.map(place => JSON.stringify(place));
    sessionStorage.setItem('places', sessionPlaces.join('@@'));
  }

  // エリア履歴のHTMLを設定
  function setPlaceHistoryHtml() {
    const sessionPlaces = getSessionPlaces();
    $('#js-place-history').html('');
    sessionPlaces.forEach(place => {
      $('#js-place-history').append(`
        <button class="StoreSearchFilter__item js-place-button ${(fixedPlaceId === place.id) ? 'is-selected' : ''}" data-place-id="${place.id}">${place.name}</button>
      `);
    });
    setPlaceButtonEvent();
  }

  // 店舗情報のHTMLを生成
  function storeHtml(store) {
    return `
      <a class="StoreSearchIndex__store js-open-store-detail" href="#" data-store-id="${store.id}">
        <div class="StoreSearchIndex__storeHeader">
          <h1 class="StoreSearchIndex__storeName">
            ${store.name}
          </h1>
          <span class="StoreSearchIndex__distance">${ConversionDistance(store.distance)}</span>
        </div>
        <div class="StoreSearchIndex__storeBody">
          <div class="StoreSearchIndex__category">
            ${store.genres.map(genre => {
              return `
                <span class="StoreSearchIndex__categoryLabel">
                  ${genre}
                </span>
              `;
              }).join('')
            }
          </div>
          <p class="StoreSearchIndex__desc">
            ${store.sales_time_information}
          </p>
        </div>
      </a>
    `;
  }
});
