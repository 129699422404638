import { getQueryParams, redirectTo } from './common';
import { openStoreDetail, toggleFavorite } from './store_common';
$(document).ready(function() {
  var enableScrollFlg = true;
  var map;
  var onlyDisplayOpenStore = false;
  var markers = [];
  var visibleMarkers = [];
  var sortedMarkers = [];
  var myLocationMarker;
  const calendarApiUrl = 'https://www.googleapis.com/calendar/v3/calendars/japanese__ja@holiday.calendar.google.com/events?';
  var isTodayHoliday = false;
  var isTomorrowHoliday = false;
  var defaultPosition;
  var currentLatitude = null;
  var currentLongitude = null;

  var apiKey;
  var groupStores;
  var favorite_store_ids;
  var new_store_ids;
  var event_store_ids;
  var currentSelectedMarker;
  var openStore;
  var storeId;
  var clusterer;
  var isFirstCenterChanged;
  var currentPosition;

  // 単一店舗ピン用
  var defaultMarkerIcon;
  var selectedMarkerIcon;
  var favoriteMarkerIcon;
  var selectedFavoriteMarkerIcon;
  var eventMarkerIcon;
  var selectedEventMarkerIcon;
  var newStoreIcon;
  var selectedNewStoreIcon;

  // 座標が同じ複数店舗の共同ピン用
  var compositeMarkerIcon;
  var selectedCompositeMarkerIcon;
  var favoriteCompositeMarkerIcon;
  var selectedFavoriteCompositeMarkerIcon;

  function getData() {
    apiKey = $('#map').data('api-key');
    groupStores = $('#map').data('group-stores');
    favorite_store_ids = $('#map').data('favorite-store-ids');
    new_store_ids = $('#map').data('new-store-ids');
    event_store_ids = $('#map').data('event-store-ids');
    openStore = $('#map').data('open-store');
    storeId = $('#map').data('store-id');
    defaultPosition = $('#map').data('default-position');
  }

  // マップ初期化
  function initMap() {
    var styles = [
      {
         "featureType": "all",
         "elementType": "labels.icon",
         "stylers": [
           {
             "visibility": "off"
           }
         ]
       }
    ]

    map = new google.maps.Map(document.getElementById('map'), {
      zoom: 16,
      minZoom: 15,
      styles: styles,
      gestureHandling: 'greedy',
      disableDefaultUI: true
    });

    // マップの中心が変わったら、その座標を取得
    new google.maps.event.addListener(map, 'idle', function() {
      const latlng = map.getCenter();
      currentLatitude = latlng?.lat() || defaultPosition.lat;
      currentLongitude = latlng?.lng() || defaultPosition.lng;
    });

    setTimeout(function() {
      if (!map.getCenter()) {
        map.setCenter(defaultPosition);
      }
    }, 3000);

    // エリアボタンを押した時、中心をそのエリアに合わせる
    $('.js-centering-area').on('click', (e) => {
      const coords = $(e.currentTarget).data('coords');
      const zoom = $(e.currentTarget).data('zoom');
      // イベントモーダルを閉じる
      $('#js-event-modal').removeClass('is-open');
      map.setCenter(coords);
      map.setZoom(zoom);
      updateCluster();
    });

    $('#current_location').on('click', () => {
      if (!currentPosition) {
        watchCurrentPosition();
      } else {
        centerMapCurrentPosition();
      }
      updateCluster();
    });

    google.maps.event.addListener(map, 'click', () => {
      closeStoreListWindow();
    });

    var storeDisplayControl = new StoreDisplayControl();
    storeDisplayControl.index = 1;

    const defaultIconScaledSize = new google.maps.Size(41, 50);
    const ActiveIconScaledSize = new google.maps.Size(41, 58);
    defaultMarkerIcon = {
      url: "/map-icon-2.png",
      scaledSize: defaultIconScaledSize
    };
    selectedMarkerIcon = {
      url: "/map-icon-1.png",
      scaledSize: ActiveIconScaledSize
    };
    favoriteMarkerIcon = {
      url: "/pin-favorite.png",
      scaledSize: defaultIconScaledSize
    };
    selectedFavoriteMarkerIcon = {
      url: "/pin-favorite-active.png",
      scaledSize: ActiveIconScaledSize
    };
    eventMarkerIcon = {
      url: "/pin-event.png",
      scaledSize: new google.maps.Size(45, 54),
    };
    selectedEventMarkerIcon = {
      url: "/pin-event-active.png",
      scaledSize: new google.maps.Size(50, 71)
    };
    newStoreIcon = {
      url: "/pin-new-store.png",
      scaledSize: new google.maps.Size(48, 60)
    };
    selectedNewStoreIcon = {
      url: "/pin-new-store-active.png",
      scaledSize: ActiveIconScaledSize
    };

    var compositeIconScaledSize = new google.maps.Size(51, 53);
    var compositeActiveIconScaledSize = new google.maps.Size(51, 58);
    var compositeIconLabelOrigin = new google.maps.Point(39, 11);
    var compositeActiveIconLabelOrigin = new google.maps.Point(39, 16);
    compositeMarkerIcon = {
      url: "/pin-count.png",
      scaledSize: compositeIconScaledSize,
      labelOrigin: compositeIconLabelOrigin
    };
    selectedCompositeMarkerIcon = {
      url: "/pin-active-count.png",
      scaledSize: compositeActiveIconScaledSize,
      labelOrigin: compositeActiveIconLabelOrigin
    };
    favoriteCompositeMarkerIcon = {
      url: "/pin-favorite-count.png",
      scaledSize: compositeIconScaledSize,
      labelOrigin: compositeIconLabelOrigin
    };
    selectedFavoriteCompositeMarkerIcon = {
      url: "/pin-favorite-active-count.png",
      scaledSize: compositeActiveIconScaledSize,
      labelOrigin: compositeActiveIconLabelOrigin
    };
    // 加盟店カードのイベント設定
    setStoreCardEvent();
  }

  // 加盟店カードのイベント設定
  function setStoreCardEvent() {
    // タップ時の誤動作を防ぐためのスワイプ時の処理を実行しない最小距離
    const minimumDistance = 40

    // スワイプ開始時の座標
    let startY = 0
    let endY = 0

    // 解説①：移動を開始した座標を取得
    $('#js-map-stores-list').on('touchstart', (e) => {
      startY = e.touches[0].pageY;
    });

    // 解説②：移動した座標を取得
    $('#js-map-stores-list').on('touchmove', (e) => {
      endY = e.changedTouches[0].pageY
    });

    // 解説③：移動距離から上下の処理を実行
    $('#js-map-stores-list').on('touchend', (_) => {
      // スワイプ終了時にy軸の移動量を取得
      // 左スワイプに対応するためMath.abs()で+に変換
      const distanceY = Math.abs(endY - startY)

      if( 0 < (endY - startY) && distanceY > minimumDistance ) {
        // 下向き
      } else if ( 0 > (endY - startY) && distanceY > minimumDistance ) {
        // 上向き
      } else {
        // スワイプ時の処理を実行しない最小距離
      }
    });

    // 横スクロールのイベント設定
    $('#js-map-stores-list').scroll(function() {
      const cardSize = $(window).width() - 30;
      const scrollLeftPoint = $(this).scrollLeft();
      const cardScrollPoint = scrollLeftPoint % cardSize;
      const cardCenterSize = Math.floor( cardSize / 2);
      if(enableScrollFlg) {
        let cardNumber = Math.floor(scrollLeftPoint / cardSize);
        if (cardCenterSize <= cardScrollPoint) cardNumber += 1;
        const marker = sortedMarkers[cardNumber];
        switchMarker(marker);
      }
    });
  }

  // 位置情報を取得してマーカーを更新する
  function handlePositionUpdate(position) {
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };
    // 初期表示時、現在地をマップの中心に設定
    if (!currentPosition) {
      map.setCenter(pos);
    }
    currentPosition = pos;

    if (myLocationMarker == null) {
      myLocationMarker = new google.maps.Marker({
        position: pos,
        map: map,
        icon: {
          url: '/my-location-icon.png',
          scaledSize: new google.maps.Size(30, 30)
        }
      });
    } else {
      myLocationMarker.setPosition(pos);
    }
  }

  // 位置情報の取得エラー処理
  function handlePositionError(e) {
    switch(e.code) {
      case 1:
        console.log('位置情報の許可がありません');
        break;
      case 2:
        console.log('位置情報の取得に失敗しました');
        break;
      case 3:
        console.log('情報の取得がタイムアウトしました');
        break;
    }
  }

  let watchId = null;

  // 現在地が変わると現在地マーカーの位置が変わるよう監視する
  function watchCurrentPosition() {
    if (navigator.geolocation) {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId); // 既存のwatchPositionをクリア
      }
      watchId = navigator.geolocation.watchPosition(handlePositionUpdate, handlePositionError, {
        enableHighAccuracy: true
      });
    } else {
      console.log('位置情報が利用できません');
    }
  }

  function centerMapCurrentPosition() {
    if (currentPosition) {
      map.setCenter(currentPosition);
    } else {
      console.log('現在地が取得できませんでした');
    }
  }

  // 現在のマップビューポート内に存在するマーカーだけを表示する
  const updateCluster = _.debounce(() => {
    clusterer.clearMarkers();
    updateVisibleMarkers();
    if (visibleMarkers.length === 0 && !isFirstCenterChanged) {
      $('#js-no-stores-message').show();
      $('#js-map-stores-list').hide();
    }
    clusterer.addMarkers(visibleMarkers);
  }, 100);

  // マーカーの追加処理
  function addMarkers() {
    // 営業日トグルをタップした際に重複してmarkerがpushされるのを防ぐため配列を一旦クリア
    if (clusterer !== undefined) {
      clusterer.clearMarkers();
    }
    markers = [];

    var count;
    var hasEvent;
    var hasFavorite;
    var hasNewStore;
    $.each(groupStores, function(groupIndex, group) {
      count = 0;
      hasEvent = false;
      hasFavorite = false;
      hasNewStore = false;
      var position = new google.maps.LatLng(group[0].latitude, group[0].longitude);
      group.map(function(store){
        if(onlyDisplayOpenStore && !isStoreOpen(store)) {
          return true;
        }
        count += 1;

        if(favorite_store_ids.includes(store.id)) {
          hasFavorite = true;
        }
        if(event_store_ids.includes(store.id)) {
          hasEvent = true;
          // 経緯度の変更
        }
        if(new_store_ids.includes(store.id)) {
          hasNewStore = true;
        }
      });
      if(count >= 1) {
        var marker = new google.maps.Marker({
          map: map,
          position: position,
          label: count > 1 ? { text: String(count),
                               color: '#ffffff',
                               fontFamily: 'sans-serif',
                               fontSize: '14px',
                               lineHeight: 1
                             } : '', // 店舗数表示
          icon: getInactiveMakeIcon(hasFavorite, hasEvent, hasNewStore, count),
          storeIds: group.map(store => store.id),
          hasEvent: hasEvent,
          hasFavorite: hasFavorite,
          hasNewStore: hasNewStore,
          count: count
        });
        marker['groupIndex'] = groupIndex;
        addMarkerClickListener(marker);
        markers.push(marker);
      }

      // 経緯度が同じ複数店舗のまとめマーカーの場合、総数-1個非表示マーカーを追加（MarkerClustererで集計数字が店舗数で出るため）
      if(count > 1) {
        addInvisibleMarkersForMarkerClustererCount(count - 1, position);
      }
    });

    clusterer = new MarkerClusterer(map, markers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 15, minimumClusterSize: 1 });

    // マップをクリックした際のイベント設定
    map.addListener('click', function () {
      // ドロップダウンを閉じる
      $('.c-dropdown__input').prop('checked', false);
    });

    // マップがズーム、または移動した際のイベント設定
    const bounds_changed_event = () => {
      // 初期表示では再検索ボタンとお店がないメッセージを非表示にする
      if (isFirstCenterChanged) {
        isFirstCenterChanged = false;
        return;
      }

      // ドロップダウンを閉じる
      $('.c-dropdown__input').prop('checked', false);

      // 加盟店カードを閉じる
      closeStoreListWindow();

      updateCluster();

      // 「加盟店が見つかりませんでした」メッセージの非表示
      $('#js-no-stores-message').hide();
    };

    map.addListener('dragstart', bounds_changed_event);
    map.addListener('zoom_changed', bounds_changed_event);

    updateCluster();
  }

  // 加盟店IDから加盟店詳細モーダルを開く
  function openStoreDetailById(storeId) {
    openStoreById(storeId);
    openStoreDetail(storeId, function() {
      const marker = findMarker(storeId);
      return marker.groupIndex;
    }, function(data) {
      // 地図の中心を店舗の座標にする
      map.setCenter(new google.maps.LatLng(data.store.latitude, data.store.longitude));
    }, function(obj) {
      const storeId = $(obj).data('store-id');
      const groupIndex = $(obj).data('group-index');
      addedFavoriteCallback(storeId, groupIndex);
    }, function(obj) {
      const storeId = $(obj).data('store-id');
      const groupIndex = $(obj).data('group-index');
      deletedFavoriteCallback(storeId, groupIndex);
    });
  }

  // 加盟店IDから対象加盟店のカードを表示する
  function openStoreById(storeId) {
    const store = findStore(storeId);
    const marker = findMarker(storeId);
    map.setCenter(new google.maps.LatLng(store.latitude, store.longitude));
    map.setZoom(16);
    new google.maps.event.trigger(marker, 'click');
  }

  // 店舗カードを表示する
  function showFullStoreCard() {
    $('#js-map-stores-window').addClass('is-shown');
  }

  // 表示されている画面内にあるマーカーをvisibleMarkersとする
  function updateVisibleMarkers() {
    const bounds = map.getBounds();
    visibleMarkers = markers.filter((marker) => {
      return marker.getVisible() && bounds && bounds.contains(marker.getPosition());
    });
  }

  // マーカーを中心座標から近い順で並び替える
  function sortMarkersByDistance(markers, center) {
    return markers.sort((a, b) => {
      const distanceA = google.maps.geometry.spherical.computeDistanceBetween(center, a.getPosition());
      const distanceB = google.maps.geometry.spherical.computeDistanceBetween(center, b.getPosition());
      return distanceA - distanceB;
    });
  }

  // 画面範囲内にある店舗を対象とした店舗カード用のHTML
  function visibleStoresListHtml() {
    const deferred = $.Deferred();
    sortedMarkers = sortMarkersByDistance(visibleMarkers, map.getCenter());
    const storeIds = sortedMarkers.map((v) => v.storeIds).flat();

    fetchStoreDetail(storeIds)
      .done(function (stores) {
        stores.forEach((store) => {
          groupStores.forEach((group, _groupIndex) => {
            const storeIndex = group.findIndex((s) => s.id === store.id);
            if (storeIndex !== -1) {
              group[storeIndex] = store;
            }
          });
        });

        sortedMarkers.forEach((marker) => {
          if (marker.groupIndex == null || marker.groupIndex < 0) return;

          const group = groupStores[marker.groupIndex];
          marker.listHtml = group
            .map((store) => storeHtml(store, marker.groupIndex))
            .join("");
        });

        const result = sortedMarkers
          .filter((marker) => marker.listHtml !== undefined)
          .map((marker) => marker.listHtml);

        deferred.resolve(result);
      })
      .fail(function (e) {
        console.error(e);
        deferred.reject(e);
      });

    return deferred.promise();
  }

  // マーカーのクリックイベントの設定
  function addMarkerClickListener(marker) {
    google.maps.event.addListener(marker, "click", function () {
      // ドロップダウンを閉じる
      $(".c-dropdown__input").prop("checked", false);
      enableScrollFlg = false;

      updateVisibleMarkers();
      visibleStoresListHtml().done(function (listHtml) {
        $("#js-map-stores-list").html(listHtml);
        $("#js-map-stores-list").show();
        showFullStoreCard();

        const markerIndex = sortedMarkers
          .map((v) => v.storeIds)
          .flat(Infinity)
          .filter((v) => v)
          .findIndex((storeIds) => storeIds === marker.storeIds[0]);

        // クリックした加盟店カードまでスクロールする
        scrollStoreCard(marker, markerIndex);
      });
    });
  }

  // 加盟店カードを強制スクロールする
  function scrollStoreCard(marker, num) {
    const cardSize = $(window).width() - 30;
    const scrollLeftPoint = cardSize * num;
    $('#js-map-stores-list').animate({
      scrollLeft: scrollLeftPoint
    }, {
      progress: function() {
        switchMarker(marker);
      },
      always: function() {
        enableScrollFlg = true;
      }
    });
  }

  // マーカ選択時の状態切り替え
  function switchMarker(marker) {
    if (currentSelectedMarker == marker || !marker.icon) {
      return;
    }

    marker.setIcon(getActiveMakeIcon(marker.hasFavorite, marker.hasEvent, marker.hasNewStore, marker.count));
    if (currentSelectedMarker != null) {
      currentSelectedMarker.setIcon(getInactiveMakeIcon(currentSelectedMarker.hasFavorite, currentSelectedMarker.hasEvent, currentSelectedMarker.hasNewStore, currentSelectedMarker.count));
    }
    currentSelectedMarker = marker;
  }

  function isStoreOpen(store) {
    if (isTodayHoliday) {
      return store.holiday;
    }
    if (isTomorrowHoliday) {
      return store.previous_holiday;
    }

    var dayOfWeek = new Date().getDay();
    switch (dayOfWeek) {
      case 0:
        return store.sunday;
      case 1:
        return store.monday;
      case 2:
        return store.tuesday;
      case 3:
        return store.wednesday;
      case 4:
        return store.thursday;
      case 5:
        return store.friday;
      case 6:
        return store.sunday;
    }
  }

  function storeHtml(store, groupIndex) {
    const favorite_class = favorite_store_ids.includes(store.id) ? 'is-added' : '';
    const storeHtml = `<div class="HomeShow-stores__item">
                    <button class="HomeShow-stores__buttonFavorite js-store-card-favorite ${favorite_class}" data-store-id=${store.id} data-group-index=${groupIndex}></button>
                    <div class="HomeShow-stores__storeInfo">
                    <h1 class="HomeShow-stores__name">${store.name}</h1>
                    <div class="HomeShow-stores__categoryList">
                    <span class="HomeShow-stores__category">${store.genre_1}</span>
                    ${store.genre_2 ? `<span class="HomeShow-stores__category">${store.genre_2}</span>` : ''}
                    </div>
                    <a href="https://www.google.com/maps/search/?api=1&query=${store.address}" class="d-block mt-2 text-body HomeShow-stores__address" onclick="linkConfirm(arguments[0]);">${store.address}</a>
                    <p class="HomeShow-stores__salesTime">営業時間：${store.sales_time_information}</p>
                    </div>
                    <div class="HomeShow-stores__actions">
                    ${[3912, 3923].includes(store.id) ? "" : `<a href="tel:${store.phone}" class="HomeShow-stores__telButton">電話</a>`}
                    <button class="HomeShow-stores__storeDetailButton js-store-detail-button" data-store-id="${store.id}">
                    店舗詳細
                    </button>
                    </div>
                   </div>
                   </div>`;
    return storeHtml;
  }

  function fetchStoreDetail(storeIds) {
    return $.ajax({
      url: '/home/show',
      type: 'GET',
      dataType: 'json',
      data: { store_ids: storeIds },
      timeout: 10000,
    });
  }

  // イベントボタン押下時に対象の加盟店カードを表示
  $('.js-store-button').on('click', function() {
    $('#js-event-modal').removeClass('is-open');
    const storeId = $(this).data('store-id');
    openStoreById(storeId);
  });

  // 取得した店舗のリストからidが一致する店舗を探す
  function findStore(storeId) {
    for (const group of groupStores) {
      for (const store of group) {
        if (store.id === storeId) {
          return store;
        }
      }
    }
    return null;
  }

  // 取得したマーカーリストから加盟店IDが一致するマーカーを探す
  function findMarker(storeId) {
    for (const marker of markers) {
      if (marker.storeIds?.includes(storeId)) {
        return marker;
      }
    }
    return null;
  }

  // イベントモーダルのイベント設定
  $('#js-event-button').on('click', function() {
    $('#js-event-modal').addClass('is-open');

    $('.js-close-event-modal').on('click', () => {
      $('#js-event-modal').removeClass('is-open');
    });
  });

  function updateMarkerByGroupIndex(groupIndex) {
    var listHtml = '';
    var hasFavorite = false;
    const group = groupStores[groupIndex];
    const marker = visibleMarkers.find((visibleMarker) => {
      return visibleMarker.groupIndex == groupIndex
    });

    group.map(function(store){
      if(onlyDisplayOpenStore && !isStoreOpen(store)) {
        return true;
      }
      listHtml += storeHtml(store, groupIndex);

      if(favorite_store_ids.includes(store.id)){
        hasFavorite = true;
      }
    });
    marker.hasFavorite = hasFavorite;
    marker['listHtml'] = listHtml;
    marker.setIcon(getActiveMakeIcon(marker.hasFavorite, marker.hasEvent, marker.hasNewStore, marker.count));
    addMarkerClickListener(marker);
  }

  function addInvisibleMarkersForMarkerClustererCount(invisibleMarkersCount, position) {
    while (invisibleMarkersCount > 0) {
      var marker = new google.maps.Marker({
          map: map,
          position: position,
          opacity: 0,
          clickable: false
      });
      markers.push(marker);
      invisibleMarkersCount--;
    }
  }

  function setIsTodayTomorrowHoliday() {
    const today = new Date();
    const todayString = today.toISOString().substring(0, 10);
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowString = tomorrow.toISOString().substring(0, 10);
    $.ajax({
      dataType: "json",
      url: calendarApiUrl,
      data: {
        key: apiKey,
        timeMin: `${todayString}T00:00:00Z`,
        timeMax: `${tomorrowString}T12:00:00Z`
      },
      async: false
    }).done(function(data) {
      $.each(data.items, function(index, item) {
        if (item.start.date == todayString) {
          isTodayHoliday = true;
        }else if (item.start.date == tomorrowString) {
          isTomorrowHoliday = true;
        }
      });
    }).fail(function(error) {
      console.log(error);
      isTodayHoliday = false;
      isTomorrowHoliday = false;
    });
  }

  // 営業曜日の加盟店だけ表示 トグルの設定
  function StoreDisplayControl() {
    $('#js-map-display-toggle').on('click', function() {
      onlyDisplayOpenStore = $(this).prop('checked');
      if (onlyDisplayOpenStore) {
        $('.HomeShow-alert').addClass('show');
      } else {
        $('.HomeShow-alert').removeClass('show');
      }
      addMarkers();
    });
  }

  function closeStoreListWindow() {
    if (currentSelectedMarker == null) {
        return;
    }

    currentSelectedMarker.setIcon(getInactiveMakeIcon(currentSelectedMarker.hasFavorite, currentSelectedMarker.hasEvent, currentSelectedMarker.hasNewStore, currentSelectedMarker.count));
    currentSelectedMarker = null;

    $('#js-map-stores-window').removeClass('is-shown');
  }

  this.linkConfirm = function(event) {
    const resultConfirm = confirm('Google MAPに移動します。よろしいですか？');
    if(!resultConfirm) {
      event.preventDefault();
    }
  }

  function getInactiveMakeIcon(hasFavorite, hasEvent, hasNewStore, count) {
    if(count == 1) {
      if (hasFavorite) {
        return favoriteMarkerIcon;
      } else if (hasEvent) {
        return eventMarkerIcon;
      } else if (hasNewStore) {
        return newStoreIcon;
      } else {
        return defaultMarkerIcon;
      }
    } else if(count > 1) {
      if (hasFavorite) {
        return favoriteCompositeMarkerIcon;
      } else {
        return compositeMarkerIcon;
      }
    }
  }

  function getActiveMakeIcon(hasFavorite, hasEvent, hasNewStore, count) {
    if(count == 1) {
      if (hasFavorite) {
        return selectedFavoriteMarkerIcon;
      } else if (hasEvent) {
        return selectedEventMarkerIcon;
      } else if (hasNewStore) {
        return selectedNewStoreIcon;
      } else {
        return selectedMarkerIcon;
      }
    } else if(count > 1) {
      if (hasFavorite) {
        return selectedFavoriteCompositeMarkerIcon;
      } else {
        return selectedCompositeMarkerIcon;
      }
    }
  }

  function addedFavoriteCallback(storeId, groupIndex) {
    favorite_store_ids.push(storeId);
    updateMarkerByGroupIndex(groupIndex);
    $('.js-store-card-favorite[data-store-id="' + storeId + '"]').addClass('is-added');
  }

  function deletedFavoriteCallback(storeId, groupIndex) {
    favorite_store_ids = $.grep(favorite_store_ids, function(value) {
      return value != storeId;
    });
    updateMarkerByGroupIndex(groupIndex);
    $('.js-store-card-favorite[data-store-id="' + storeId + '"]').removeClass('is-added');
  }

  if($('#map').length != 0) {
    // マップ表示 暫定モーダル表示
    const openWaitingModal = $('#map').data('open-waiting-modal');
    if (openWaitingModal) $("#js-map-waiting-modal").addClass('is-open');
    getData();
    setIsTodayTomorrowHoliday();
    initMap();

    // モーダルの中身は動的に生成されるのでその親要素にイベントリスナーを設定する
    $('#js-map-stores-window').on('click', '.js-store-detail-button', function() {
      const storeId = $(this).data('store-id');
      openStoreDetail(storeId, function() {
        const marker = findMarker(storeId);
        return marker.groupIndex;
      }, function(data) {
        // 地図の中心を店舗の座標にする
        map.setCenter(new google.maps.LatLng(data.store.latitude, data.store.longitude));
      }, function(obj) {
        const storeId = $(obj).data('store-id');
        const groupIndex = $(obj).data('group-index');
        addedFavoriteCallback(storeId, groupIndex);
      }, function(obj) {
        const storeId = $(obj).data('store-id');
        const groupIndex = $(obj).data('group-index');
        deletedFavoriteCallback(storeId, groupIndex);
      });
    });

    $('#js-map-stores-window').on('click', '.js-store-card-favorite', function() {
      const storeId = $(this).data('store-id');
      const groupIndex = $(this).data('group-index');
      toggleFavorite(storeId, function() {
        addedFavoriteCallback(storeId, groupIndex);
      }, function() {
        deletedFavoriteCallback(storeId, groupIndex);
      });
    });

    google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
      addMarkers();
      if (openStore) {
        // 加盟店詳細モーダルを開いた状態で表示する
        openStoreDetailById(openStore.id);
      } else if(storeId) {
        // 加盟店カードを開いた状態で表示する
        openStoreById(storeId);
      } else {
        // 現在地許可の確認
        watchCurrentPosition();
      }
    });
  } else {
    // マップ画面とは違う処理を行う
  }

  /*
  /* グローバルナビゲーションの行きたい・履歴モーダル関連処理
  /* マップ画面とそれ以外の画面を開いているときで処理を分岐したいため、
  /* 気持ち悪いが本JSファイルに記載する
  */
  const isMap = $('#js-navigation').data('is-map');

  $('.js-search-nav').click(function() {
    var params = getQueryParams('mode');
    if (currentLatitude && currentLongitude) {
      params['latitude'] = currentLatitude;
      params['longitude'] = currentLongitude;
    }
    redirectTo('/search', params);
  });

  $('#js-favorite-nav').click(function() {
    // 履歴モーダルを閉じる
    $('#js-coupon-used-histories-modal').removeClass('is-open');

    // 加盟店詳細モーダルを閉じる
    $('#js-store-detail-modal').removeClass('is-open');

    $('#spinner-div').show();
    $.ajax({
      url: '/api/v1/favorites',
      type: 'GET',
      dataType: 'json',
      timeout: 10000,
    }).done(function(data) {
      openFavoritesModal(data.favorites);
      $('#js-favorites-modal').addClass('is-open');
    }).fail(function(e) {
      if (e.responseJSON) {
        location.href = e.responseJSON.redirect_url;
      } else {
        console.log(e);
        alert('行きたい一覧の取得に失敗しました。');
      }
    }).always(function() {
      $('#spinner-div').hide();
    });
  });

  $('#js-coupon_used_history-nav').click(function() {
    // 行きたいモーダルを閉じる
    $('#js-favorites-modal').removeClass('is-open');

    // 加盟店詳細モーダルを閉じる
    $('#js-store-detail-modal').removeClass('is-open');

    $('#spinner-div').show();
    $.ajax({
      url: '/api/v1/coupon_used_histories',
      type: 'GET',
      dataType: 'json',
      timeout: 10000,
    }).done(function(data) {
      openCouponUsedHistoriesModal(data.coupon_used_histories);
      $('#js-coupon-used-histories-modal').addClass('is-open');
    }).fail(function(e) {
      if (e.responseJSON) {
        location.href = e.responseJSON.redirect_url;
      } else {
        console.log(e);
        alert('クーポン一覧の取得に失敗しました。');
      }
    }).always(function() {
      $('#spinner-div').hide();
    });
  });

  $('.js-map-waiting-modal-close').on('click', () => {
    $("#js-map-waiting-modal").removeClass('is-open');
  });

  function openFavoritesModal(favorites) {
    // モーダルの中身を生成
    const modalHtml = generateFavoritesModal(favorites);
    // モーダルの中身を書き換える
    $('#js-favorites-modal-body').html(modalHtml);

    $('.js-close-favorites-modal').on('click', () => {
      $('#js-favorites-modal').removeClass('is-open');
    });
  }

  this.clickStoreList = function(obj) {
    const storeId = $(obj).data('store-id');

    if (isMap) {
      // 開いているモーダルを閉じる
      $(obj).parents('.is-open').removeClass('is-open');
      // 加盟店詳細モーダルの表示
      openStoreDetailById(storeId);
    } else {
      location.href = `/?open_store_detail=${storeId}`;
    }
  }

  function generateFavoritesModal(favorites) {
    return favorites.map((favorite) =>
      `<li class="p-modalFavorites__item">
        <a href="#" class="p-modalFavorites__itemLink" onclick="clickStoreList(this);" data-store-id="${favorite.store_id}">
          <div class="p-modalFavorites__storeName">${favorite.name}</div>
          <div class="p-modalFavorites__storeInfo">営業時間：${favorite.sales_time_information}</div>
        </a>
      </li>`
    ).join('');
  }

  function openCouponUsedHistoriesModal(couponUsedHistories) {
    // モーダルの中身を生成
    const modalHtml = generateCouponUsedHistoriesModal(couponUsedHistories);
    // モーダルの中身を書き換える
    $('#js-coupon-used-histories-modal-body').html(modalHtml);

    $('.js-close-coupon-used-histories-modal').on('click', () => {
      $('#js-coupon-used-histories-modal').removeClass('is-open');
    });
  }

  function generateCouponUsedHistoriesModal(couponUsedHistories) {
    return couponUsedHistories.map((couponUsedHistory) =>
      `<li class="p-modalCouponUsedHistories__item">
        <a href="#" class="p-modalCouponUsedHistories__itemLink" onclick="clickStoreList(this);" data-store-id="${couponUsedHistory.store.id}">
          <div class="p-modalCouponUsedHistories__used_at">${couponUsedHistory.used_at}</div>
          <div class="p-modalCouponUsedHistories__storeName">${couponUsedHistory.store.name}</div>
          ${couponUsedHistory.reject_coupon_page ? '' : `<button class="p-modalCouponUsedHistories__couponButton" onclick="location.href='/coupon_used_histories/${couponUsedHistory.id}';">クーポンを再表示する</button>`}
        </a>
      </li>`
    ).join('');
  }
});
