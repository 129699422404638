// 対象の加盟店のお気に入りを追加・削除する
function toggleFavorite(storeId, addedCallback = function(){}, deletedCallback = function(){}) {
  $.ajax({
    url: '/api/v1/favorites/toggle',
    type: 'POST',
    dataType: 'json',
    data: { store_id: storeId },
    timeout: 3000,
  }).done(function(data) {
    if (data.deleted) {
      deletedCallback();
    } else {
      addedCallback();
    }
  }).fail(function(e) {
    if (e.responseJSON) {
      location.href = e.responseJSON.redirect_url;
    } else {
      console.log(e);
      alert('お気に入りを追加・削除に失敗しました。');
    }
  })
}

// 「クーポンはこちら」ボタンを押した時、クーポン利用確認モーダルの中身を書き換える
function openCouponConfirmModal(storeId, appName) {
  // モーダルの中身を生成
  const modalHtml = generateCouponConfirmModal(storeId, appName);
  // モーダルの中身を書き換える
  $("#js-coupon-confirm-modal-body").html(modalHtml);
  // クーポンを発行するボタンのイベント設定
  $('#js-create-coupon').on('click', function() {
    const storeId = $(this).data('store-id');
    $.ajax({
      url: '/api/v1/coupon_used_histories',
      type: 'POST',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      dataType: 'json',
      data: { coupon_used_history: { store_id: storeId } },
      timeout: 3000,
    }).done(function(response) {
      window.location.href = response.redirect_url
    }).fail(function(_) {
      alert('クーポンの発行に失敗しました。時間をおいて再度発行してください。');
    })
  });
}

// クーポン利用確認モーダルの中身
function generateCouponConfirmModal(storeId, appName) {
  return `
    <div class="l-modal__main">
      <div class="l-modal__body HomeShow-couponConfirmModal__body">
        <div class="l-modal__scrollArea">
          <div class="l-modal__scrollContents">
            <div class="HomeShow-couponConfirmModal__lead">
              ご利用前にご確認ください
            </div>
            <div class="p-couponTerms">
              <div class="p-couponTerms__heading">利用条件</div>
              <ul class="p-couponTerms__list">
                <li class="p-couponTerms__item">660円（税込）以下のドリンクに加え加盟店が指定するドリンクが一杯無料となります。</li>
                <li class="p-couponTerms__item">ドリンク1杯無料サービスを受けられる方は、${appName}会員でプラン選択をした方のみです。</li>
                <li class="p-couponTerms__item p-couponTerms__item--emphasis">来店者一人当たり1,100円（税込）以上の会計（無料対象ドリンクの代金は除く）が必要です。</li>
                <li class="p-couponTerms__item">クーポン発行は1日何店舗でも可能ですが、クーポン発行済みの店舗は同日に再発行はできません。</li>
                <li class="p-couponTerms__item">クーポン画面の発行後、別の加盟店のクーポン画面を発行すると前のクーポン画面はご利用できません。</li>
                <li class="p-couponTerms__item">クーポン発行は毎日午後3時から翌日午前3時までとなります。</li>
                <li class="p-couponTerms__item">他のクーポンとの併用可否は、加盟店によって異なりますので、直接お問い合わせください。</li>
              </ul>
            </div>
            <p class="HomeShow-couponConfirmModal__text">
              よろしければ「条件に同意してクーポンを発行する」ボタンを押してください。
            </p>
          </div>
        </div>
      </div>
      <div class="HomeShow-couponConfirmModal__footer">
        <a id="js-create-coupon" class="HomeShow-couponConfirmModal__issueButton" data-store-id=${storeId}>
          条件に同意してクーポンを発行する
        </a>
        <button class="HomeShow-couponConfirmModal__backButton js-close-coupon-confirm-modal">
          店舗詳細へ戻る
        </button>
      </div>
    </div>
    <button class="l-modal__closeButton js-close-coupon-confirm-modal">
    <span class="l-modal__closeButtonLabel">閉じる</span>
    </button>
  `;
}

// 店舗詳細モーダルの中身
function generateStoreDetailModal(couponAvailability, getGroupIndexByMarker) {
  return `
    <div class="l-modal__main">
      <div class="l-modal__body HomeShow-storeDetailModal__body">
        <h1 class="HomeShow-storeDetailModal__storeName">${couponAvailability.store.name}</h1>
        <div class="HomeShow-storeDetailModal__categoryList">
          <span class="HomeShow-storeDetailModal__category">${couponAvailability.store.genre_1}</span>
          ${couponAvailability.store.genre_2 ? `<span class="HomeShow-storeDetailModal__category">${couponAvailability.store.genre_2}</span>` : ''}
        </div>
        <div class="l-modal__scrollArea">
          <div class="l-modal__scrollContents">
            <div class="HomeShow-storeDetailModal__storeInfo">
              <a class="HomeShow-storeDetailModal__storeInfoLink is-map" href="https://www.google.com/maps/search/?api=1&query=${couponAvailability.store.prefecture + couponAvailability.store.city + couponAvailability.store.street + couponAvailability.store.building}" onclick="linkConfirm(arguments[0]);">${couponAvailability.store.prefecture + couponAvailability.store.city + couponAvailability.store.street + couponAvailability.store.building}</a>
            </div>
            <div class="HomeShow-storeDetailModal__storeInfo">
              <a class="HomeShow-storeDetailModal__storeInfoLink is-tel" href="tel:${couponAvailability.store.phone}">${[3912, 3923].includes(couponAvailability.store.id) ? "" : couponAvailability.store.phone}</a>
            </div>
            <div class="HomeShow-storeDetailModal__storeInfo">
              <a class="HomeShow-storeDetailModal__storeInfoLink is-website" href="${couponAvailability.store.web_url}">${couponAvailability.store.web_url == null ? "" : couponAvailability.store.web_url}</a>
            </div>
            <section class="HomeShow-storeDetailModal__section">
              <h2 class="HomeShow-storeDetailModal__heading">営業時間</h2>
              <p class="HomeShow-storeDetailModal__text">${couponAvailability.store.sales_time_information}</p>
            </section>
            <section class="HomeShow-storeDetailModal__section">
              <h2 class="HomeShow-storeDetailModal__heading">ひとことコメント</h2>
              <p class="HomeShow-storeDetailModal__text">${couponAvailability.store.comment == null ? "" : couponAvailability.store.comment}</p>
            </section>
            ${location.pathname !== '/' ? `<button onclick="location.href='/?store_id=${couponAvailability.store.id}'" class="HomeShow-storeDetailModal__buttonMap" type="button">マップに移動</button>` :
                                          '<button class="HomeShow-storeDetailModal__buttonMap js-close-store-detail-modal" type="button">閉じる</button>'}            
          </div>
        </div>
      </div>
      <div class="HomeShow-storeDetailModal__footer">
        ${generateStoreDetailModalFooter(couponAvailability, getGroupIndexByMarker)}
      </div>
    </div>
    <button class="l-modal__closeButton js-close-store-detail-modal">
      <span class="l-modal__closeButtonLabel">閉じる</span>
    </button>
  `;
}

// 加盟店詳細情報を取得し、モーダルを表示する
function openStoreDetail(storeId, getGroupIndexByMarker = function(){}, successCallback = function(){}, addedFavoriteCallback = function(){}, deletedFavoriteCallback = function(){}) {
  $("#spinner-div").show();
  $.ajax({
    url: '/home/coupon_availability',
    type: 'GET',
    dataType: 'json',
    data: { store_id: storeId },
    timeout: 10000,
  }).done(function(data) {
    openStoreDetailModal(data, getGroupIndexByMarker, addedFavoriteCallback, deletedFavoriteCallback);
    successCallback(data);
  }).fail(function(e) {
    console.error(e);
  }).always(function() {
    $("#spinner-div").hide();
  });
}

// 店舗詳細ボタンを押した時にモーダルの中身を書き換える
function openStoreDetailModal(couponAvailability, getGroupIndexByMarker, addedFavoriteCallback, deletedFavoriteCallback) {
  // モーダルの中身を生成
  const modalHtml = generateStoreDetailModal(couponAvailability, getGroupIndexByMarker);
  // モーダルの中身を書き換える
  $("#js-store-detail-modal-body").html(modalHtml);
  // モーダルを開く
  $("#js-store-detail-modal").addClass('is-open');
  // 加盟店詳細モーダルのイベント設定
  setStoreDetailModalEvent(addedFavoriteCallback, deletedFavoriteCallback);
}

// 加盟店詳細モーダルのイベント設定
function setStoreDetailModalEvent(addedFavoriteCallback, deletedFavoriteCallback) {
  // 行きたいボタンのイベント設定
  $("#js-store-detail-toggle-favorite").on("click", (event) => {
    const storeId = $(event.target).data('store-id');
    toggleFavorite(storeId, function() {
      $(event.target).addClass('is-added');
      addedFavoriteCallback(event.target);
    }, function() {
      $(event.target).removeClass('is-added');
      deletedFavoriteCallback(event.target);
    });
  });
  // クーポンはこちらボタンのイベントの設定
  $('#js-store-detail-modal').on('click', '.js-coupon-confirm-button', function() {
    const storeId = $(this).data('store-id');
    const appName = $('#js-coupon-confirm-modal').data('app-name');
    openCouponConfirmModal(storeId, appName);
    $("#js-coupon-confirm-modal").addClass('is-open');
    $(".js-close-coupon-confirm-modal").on("click", () => {
      $("#js-coupon-confirm-modal").removeClass('is-open');
    });
  });
  // モーダルを閉じるボタンのイベントの設定
  $(".js-close-store-detail-modal").on("click", () => {
    $("#js-store-detail-modal").removeClass('is-open');
  });
}

// 店舗詳細モーダルのフッター（クーポン利用不可時などいくつかの条件で表示を切り替える）
function generateStoreDetailModalFooter(couponAvailability, getGroupIndexByMarker = function(){ return 0; }) {
  const lineOfficialAccountUrl = $('#js-navigation').data('line-official-account-url');
  const favoriteClass = couponAvailability.favorite ? 'is-added' : '';
  const groupIndex = getGroupIndexByMarker();
  if (couponAvailability.not_logged_in) { // 未ログインの場合
    return `
      <div class="HomeShow-storeDetailModal__alert is-nonLogin">
        <div class="HomeShow-storeDetailModal__buttons">
          <button id="js-store-detail-toggle-favorite" class="HomeShow-storeDetailModal__buttonFavorite ${favoriteClass}" data-store-id=${couponAvailability.store.id} data-group-index=${groupIndex}>
            <i class="fas fa-heart HomeShow-storeDetailModal__buttonFavoriteIcon"></i>
            行きたい
          </button>
          <button class="HomeShow-storeDetailModal__buttonCoupon js-coupon-confirm-button" disabled="disabled" data-store-id=${couponAvailability.store.id}>
            クーポンはこちら
          </button>
        </div>
        <p class="HomeShow-storeDetailModal__alertText">
          クーポンを発行するにはログインが必要です。
          <br>
          LINE公式アカウントからログイン画面を開いて、会員登録のうえログインしてください。
        </p>
        <a href="${lineOfficialAccountUrl}" class="c-button p-button--LINE HomeShow-storeDetailModal__alertButton">
          LINE公式アカウントへ
        </a>
      </div>`;
  } else if (couponAvailability.no_contract) { // 契約なしの場合
    return `
      <div class="HomeShow-storeDetailModal__alert is-invalid">
        <div class="HomeShow-storeDetailModal__buttons">
          <button id="js-store-detail-toggle-favorite" class="HomeShow-storeDetailModal__buttonFavorite ${favoriteClass}" data-store-id=${couponAvailability.store.id} data-group-index=${groupIndex}>
            <i class="fas fa-heart HomeShow-storeDetailModal__buttonFavoriteIcon"></i>
            行きたい
          </button>
          <button class="HomeShow-storeDetailModal__buttonCoupon js-coupon-confirm-button" disabled="disabled" data-store-id=${couponAvailability.store.id}>
            クーポンはこちら
          </button>
        </div>
        <p class="HomeShow-storeDetailModal__alertText">
          お客さまは有効なプラン選択をお済ではありません。プラン選択ページよりプランをお申込みください。
        </p>
        <a href="/service_contracts" class="c-button p-button--danger HomeShow-storeDetailModal__alertButton">
          プラン選択へ
        </a>
      </div>`;
  } else if (couponAvailability.in_reject_business_time) { // 利用時間外の場合
    return `
      <div class="HomeShow-storeDetailModal__alert is-invalid">
        <div class="HomeShow-storeDetailModal__buttons">
          <button id="js-store-detail-toggle-favorite" class="HomeShow-storeDetailModal__buttonFavorite ${favoriteClass}" data-store-id=${couponAvailability.store.id} data-group-index=${groupIndex}>
            <i class="fas fa-heart HomeShow-storeDetailModal__buttonFavoriteIcon"></i>
            行きたい
          </button>
          <button class="HomeShow-storeDetailModal__buttonCoupon js-coupon-confirm-button" disabled="disabled" data-store-id=${couponAvailability.store.id}>
            クーポンはこちら
          </button>
        </div>
        <p class="HomeShow-storeDetailModal__alertText">
          クーポンが発行できるのは、午後3時〜翌午前3時までとなります。
        </p>
      </div>`;
  } else if (couponAvailability.same_store_coupon_issued) { // 同一店舗発行済みの場合
    return `
      <div class="HomeShow-storeDetailModal__alert is-invalid">
        <div class="HomeShow-storeDetailModal__buttons">
          <button id="js-store-detail-toggle-favorite" class="HomeShow-storeDetailModal__buttonFavorite ${favoriteClass}" data-store-id=${couponAvailability.store.id} data-group-index=${groupIndex}>
            <i class="fas fa-heart HomeShow-storeDetailModal__buttonFavoriteIcon"></i>
            行きたい
          </button>
          <button class="HomeShow-storeDetailModal__buttonCoupon js-coupon-confirm-button" disabled="disabled" data-store-id=${couponAvailability.store.id}>
            クーポンはこちら
          </button>
        </div>
        <p class="HomeShow-storeDetailModal__alertText">
          この加盟店では、本日（午後3時〜翌午前3時）すでにクーポンを発行済みです。
        </p>
      </div>`;
  } else if (couponAvailability.coupon_issued) { // クーポン発行済みの場合
    return `
      <div class="HomeShow-storeDetailModal__alert is-issued">
        <div class="HomeShow-storeDetailModal__buttons">
          <button id="js-store-detail-toggle-favorite" class="HomeShow-storeDetailModal__buttonFavorite ${favoriteClass}" data-store-id=${couponAvailability.store.id} data-group-index=${groupIndex}>
            <i class="fas fa-heart HomeShow-storeDetailModal__buttonFavoriteIcon"></i>
            行きたい
          </button>
          <button class="HomeShow-storeDetailModal__buttonCoupon" onclick="location.href='/coupon_used_histories/${couponAvailability.coupon_id}'">
            クーポンを再表示する
          </button>
        </div>
        <p class="HomeShow-storeDetailModal__alertText">
          この加盟店のクーポンは発行済みです。
        </p>
      </div>`;
  } else {
    return `
      <div class="HomeShow-storeDetailModal__alert">
        <div class="HomeShow-storeDetailModal__buttons">
          <button id="js-store-detail-toggle-favorite" class="HomeShow-storeDetailModal__buttonFavorite ${favoriteClass}" data-store-id=${couponAvailability.store.id} data-group-index=${groupIndex}>
            <i class="fas fa-heart HomeShow-storeDetailModal__buttonFavoriteIcon"></i>
            行きたい
          </button>
          <button class="HomeShow-storeDetailModal__buttonCoupon js-coupon-confirm-button" data-store-id=${couponAvailability.store.id}>
            クーポンはこちら
          </button>
        </div>
      </div>`;
  }
}
export { openStoreDetail, openCouponConfirmModal, toggleFavorite };
